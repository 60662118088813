body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

/* ::-webkit-scrollbar {

}

::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #f5f5f5;
}

::-webkit-scrollbar {
	width: 4px;
	background-color: #f5f5f5;
	border-radius: 20px;
}*/

.MuiFormControlLabel-root {
	position: relative;
	margin-left: 0px !important;
	margin-right: 0px !important;
	margin-bottom: 0px;
}
canvas {
	margin-left: -9.8rem;
}

@media only screen and (min-width: 320px) and (max-width: 820px) {
	canvas {
		margin-left: 0rem;
	}
}

.indicatorStyling {
	position: absolute;
}

.swiper-button-next,
.swiper-button-prev {
	border-radius: 50%;
	border: 1px solid;
	width: 35px;
	height: 35px;
	top: 50%;
}

.swiper-button-next:after,
.swiper-button-prev:after {
	font-size: 10px;
}

.swiper-button-next {
	right: 0%;
	background: #ed1c24;
	color: #fff;
}

.swiper-button-prev {
	left: 0%;
	background: #ed1c24;
	color: #fff;
}
.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
	border: 1px solid #ed1c24;
	color: #ed1c24;
	background: transparent;
}

@media only screen and (min-width: 320px) and (max-width: 820px) {
	.swiper-container {
		width: 320px;
	}
}

div[columnscount="1"] {
	margin-bottom: 20px;
}

@media only screen and (min-width: 320px) and (max-width: 820px) {
	/* .nextBtn {
		right: -5px;
	}
	.prevBtn {
		left: -5px;
	} */
	button[aria-label="Next"] {
		right: -8px;
	}

	button[aria-label="Previous"] {
		left: -8px;
	}
}

button[aria-label="Next"]:hover {
	background-color: transparent !important;
	opacity: 1;
}
button[aria-label="Previous"]:hover {
	background-color: transparent !important;
	opacity: 1;
}
